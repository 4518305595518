import { OidcConfiguration, OidcClient } from '@axa-fr/oidc-client';

const azureOidcTenantId = '4a75cb31-b10b-4b66-acf2-a4237ae4d9c9';
const azureOidcAppId = 'f509cfa5-5fe7-44a3-9b42-a785e32bae72';

const configuration: OidcConfiguration = {
  client_id: azureOidcAppId,
  redirect_uri: `${window.location.origin}/authentication-callback.html`,
  scope: `openid ${azureOidcAppId}/.default`,
  authority: `https://login.microsoftonline.com/${azureOidcTenantId}/v2.0`,
  service_worker_relative_url: '/OidcServiceWorker.js',
  service_worker_only: true,
}

export const oidc = OidcClient.getOrCreate(() => fetch)(configuration);

// @ts-expect-error
window.oidc = oidc;